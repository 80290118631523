.footer-container{
    position: relative;
}
.footer-container hr{
    border: 1px solid var(--lightgray);
}
.footer{
    padding: 1rem 2rem;
    gap: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20rem;
}
.social-links{
    display: flex;
    gap: 4rem;
}
.social-links img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.logo-f{
    width: 8rem;
}
@media  screen and (max-width:768px) {
    
    .logo-f{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .logo-f img{
        width: 9rem;
        height: 4rem;
    }
}
