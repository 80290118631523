.Programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}
.program_header{
    color: #fff;
    display: flex;
    font-size: 3rem;
    font-style: italic;
    font-weight: 700;
    gap: 5rem;
    justify-content: center;
    text-transform: uppercase;

}
.program-catgories{
    display: flex;
    gap: 1rem;
}
.category{
    display: flex;
   flex-direction: column;
   padding: 2rem;
   gap: 1rem;
   background-color: grey;
   color: white;
   justify-content: space-between; 
   cursor: pointer;
}
.category :nth-child(1){
    width: 2rem;
    height: 2rem;
    fill: white;
}
.category:nth-child( 2){
    font-size: 1rem;
    font-weight: bold;
}
.category:nth-child( 3){
  font-size: 0.9rem;
  line-height: 25px;
}
.join-now{
    display: flex;
    align-items: center;
    gap: 2rem;
}
.join-now img{
    width:1rem;
}
.category:hover {
    background-color: var(--orange);
}
@media  screen and (max-width:768px) {
    .program_header{
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }
    .program-catgories{
        flex-direction: column;
    }
}