.reasons {
    display: flex;
    gap: 2rem;
    padding: 0 2rem;
  }
  
  .left-r {
    grid-gap: 1rem;
    display: grid;
    flex: 1 1;
    gap: 1rem;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(3, 1fr);
  }
  
  .left-r > img {
    object-fit: cover;
  }
  
  .left-r>:first-child {
    grid-row: 1 / 3;
    height: 28rem;
    width: 12rem;
}
  
  .left-r>:nth-child(2) {
    grid-column: 2 / 4;
    height: 16rem;
    width: auto;
}
.left-r>:nth-child(3) {
    grid-column: 2 / 3;
    grid-row: 2;
    width: 14rem;
}
.left-r>:nth-child(4) {
    grid-column: 3 / 4;
    grid-row: 2;
    height: 11.2rem;
    width: 10rem;
}
  .right-r {
  flex: 1 1;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  text-transform: uppercase;

  }
  .right-r>span {
    font-weight: bold;
    color:var(--orange);

  }
  .right-r div{
    color: white;
    font-size: 3rem;
    font-weight: bold; 
  }
  .details-r{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: white;
  }
  .details-r>div{
    display: flex;
    font-size: 1rem;
    gap: 1rem;
  }
 .details-r>div>img{
  width: 2rem;
  height: 2rem;
 }
 .partners{
  display: flex;
  gap: 1rem;
 }
 .partners>img{
  width: 2.5rem;
 }
 @media  screen and (max-width:768px) {
  .reasons{
    flex-direction: column;
  }
  .left-r{
    grid-auto-rows: auto;
    overflow: hidden;
  }
  .left-r :nth-child(1){
    width: 7rem;
    height: 17rem;
  }
  .left-r :nth-child(2){
    width: 15rem;
    height: 10rem;
  }
  .left-r :nth-child(3){
    width: 7rem;
  }
  .left-r :nth-child(4){
    width: 7rem;
    height: 6rem;
  }
  .programs-header{
    display: flex;
    flex-direction: column;
  justify-content: center;
  align-items: center;
  }
  .programs-header >span{
    font-size: 1.6rem;
  }
 }