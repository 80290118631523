.hero{
    display: flex;
    justify-content: space-between;
 ;
}
.left-h{
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}
.right-h{
    flex: 1;background-color: var(--orange);
    position: relative;
    
}

.ad{
    margin-top: 4rem;
    background-color:#363d42;
    color: white;
    border-radius: 4rem;
    text-transform: uppercase;
    width: fit-content;
   padding: 20px  13px;
   position: relative;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   
}
.ad span{
    z-index: 2;
}
.ad div{
    position: absolute;
    background-color: var(--orange);
    width: 5.4rem;
    height: 80%;
    border-radius: 3rem;
    left: 8px;
    z-index: 2;
}

.Hero-text{
    display: flex;
    flex-direction: column;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    gap: 2rem;
    text-transform:uppercase ;
}
.Hero-text div:nth-child(3){
    font-size: 1rem;
    font-weight: 200;
    text-transform:lowercase;
    letter-spacing: 1px;
    width: 80%;
}
.figures{
    display: flex;
    gap: 2rem;
}
.figures div{
    display: flex;
    flex-direction: column;
}
.figures div span:nth-child(1){
    font-size: 2rem;
    color: white;
}
.figures div span:nth-child(2){
    color: var(--grey);
    text-transform: uppercase;
}
.hero-buttons{
    display: flex;
    gap: 1rem;
    font-weight: normal;
  }
  .hero-buttons button:nth-child(1){
   color: white;
   background-color: var(--orange);
   width: 8rem;
  }
  .hero-buttons button:nth-child(2) {
    width: 8rem;
   background-color: transparent ;
   border: 2px solid var(--orange);
   color: white;
}

.right-h .btn{
position: absolute;
right: 3rem;
top: 2rem;
color: black;
}
.heart-rate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding: 1rem;
    align-items: start;
    border-radius: 5px;
    position: absolute;
    right: 4rem;
    top: 7rem;
}
.heart-rate img{
    width: 2rem;
}
.heart-rate span:nth-child(2){
  color: var(--gray);
}
.heart-rate span:nth-child(3){
  color: white;
  font-size: 1.5rem;
}

.hero_image{
    position: absolute;
    top: 10rem;
    right: 8rem;
    width: 23rem;
}
.hero_image_back{
    position: absolute;
    z-index: -1;
    top: 4rem;
    right: 20rem;
    width: 15rem;
}
.calories{
    display: flex;
    gap: 2rem;
    background-color: var(--caloryCard);
    border-radius:5px;
    padding: 1rem;
    width: fit-content;
    position: absolute;
    top: 32rem;
    right: 28rem;
}
.calories img{
    width: 3rem;
}
.calories > div{

}
.hero-blur{
    width: 22rem;
    height: 30rem;
    left: 0;
}

@media  screen and (max-width:768px) {
    
        .hero{
flex-direction: column;
        }
    .hero-blur{
        width: 14rem;
    }

.ad{
    margin-top: 0;
    font-size: small;
    align-items: center;
    transform: scale(0.8);
}
.Hero-text{
  font-size: xx-large;  
  align-items: center;
  justify-content: center;
}
.Hero-text div:nth-of-type(3){
    font-size: small;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
}
.hero-buttons{
    justify-content: center;
}
.figures >div>span:nth-of-type(1){
    font-size: large;
}
.figures >div>span:nth-of-type(2){
    font-size: small;
}
.right-h{
    position: relative;
background: none;
}
.heart-rate{
    left: 1rem;
    top: 2rem;
}
.calories{
    position: relative;
    left: 1rem;
    top: 5rem;
    width: 12rem;
}
.calories>img{
    width: 2rem;
}
.calories>div> :nth-child(2){
    color: white;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
}
.hero_image{
    position: relative;
    width:15rem ;
    left: 7rem;
    top: 4rem;
}
.hero_image_back{
    width: 15rem;
    left: 2rem;
    top: 0rem;
}
}