.header{
    position: relative;
}
.logo {
    height: 3rem;
    width: 10rem;
  }
  
  .header-menu {
        position: absolute;
        right: 0.4rem;
        top: 0.01rem;
    display: flex;
    list-style: none;
    gap: 2rem;
    color: white;
  }
  
  .header-menu li{
    cursor: pointer;
    font-size: 1rem;
  }
  .header-menu li :hover{
    color: brown;
  }
  @media  screen and (max-width:768px) {
    .header>:nth-child(2){
      position: fixed;
      right: 2rem;
      z-index: 99;
    }
    .header-menu{
      flex-direction: column;
      background-color: var(--appColor);
      padding: 2rem;
    }
  }
  
 